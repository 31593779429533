<template>
  <div class="h-100 d-flex flex-column justify-content-between">
    <div class="h-100">
      <b-table
        ref="baseTable"
        :busy.sync="$isBusy"
        :sort-by.sync="$sortBy"
        :sort-desc.sync="$sortDesc"
        :items="itemData"
        :fields="$fields"
        hover
        responsive
        thead-class="thead-light"
        class="mb-0"
        v-bind="{ ...$attrs, ...$props }"
        v-on="{ ...$listeners }"
      >
        <template #head(actions)>
          <div class="text-center">
            <span>{{ actionLabel }}</span>
          </div>
        </template>

        <template v-if="showRefresh" #head(refresh)>
          <div class="text-center">
            <a
              href="javascript:void(0)"
              class="btn btn-sm text-primary btn-link pl-0"
              v-b-tooltip.hover
              title="Refresh"
              @click.prevent="refresh"
            >
              <font-awesome-icon :spin="$isBusy" size="sm" icon="sync" />
            </a>
          </div>
        </template>

        <template #cell(order)="{ index }">
          <div class="text-right">
            {{ ($currentPage - 1) * perPage + (index + 1) }}
          </div>
        </template>

        <template
          v-for="(_, name) in $scopedSlots"
          :slot="name"
          slot-scope="data"
        >
          <slot :name="name" v-bind="data" />
        </template>
      </b-table>

      <div class="divider"></div>
      <div class="divider"></div>
    </div>
    <div>
      <div class="p-3">
        <b-pagination-nav
          v-model.number="$currentPage"
          :link-gen="linkGenFn"
          :number-of-pages="numberOfPages > 0 ? numberOfPages : 1"
          align="center"
          use-router
        >
          <template #first-text>
            <font-awesome-icon icon="angle-double-left" />
          </template>

          <template #prev-text>
            <font-awesome-icon icon="angle-left" />
          </template>

          <template #next-text>
            <font-awesome-icon icon="angle-right" />
          </template>

          <template #last-text>
            <font-awesome-icon icon="angle-double-right" />
          </template>
        </b-pagination-nav>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSync,
  // faQuestionCircle,
  faEllipsisV,
  faEllipsisH,
  faChevronLeft,
  // faUser,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { debounce } from "lodash";

library.add(faSync, faEllipsisH, faEllipsisV, faChevronLeft, faChevronRight);

export default {
  props: {
    fields: Array,
    provider: [Function, Array],
    isRefreshing: Boolean,
    actionLabel: {
      type: String,
      default: "",
    },
    linkGenFn: {
      type: Function,
      default: () => {
        return "?";
        // return pageNumber === 1 ? "?" : `?page=${pageNumber}`;
      },
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
    numberOfPages: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    showAction: {
      type: Boolean,
      default: true,
    },
    showRefresh: {
      type: Boolean,
      default: true,
    },
    sortBy: {
      type: String,
      default: "",
    },
    sortDesc: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      itemData: null,
    };
  },

  watch: {
    provider: {
      immediate: true,
      handler: "setDataItem",
    },
  },

  computed: {
    $fields() {
      const fields = [
        { key: "order", label: "ลำดับ", thStyle: "width: 25px;" },
        ...this.fields,
        // { key: "actions", label: this.actionLabel },
        // { key: "refresh", label: "", thStyle: "width: 25px;" },
      ];

      if (this.showAction) {
        fields.push({ key: "actions", label: this.actionLabel });
      }

      fields.push({ key: "refresh", label: "", thStyle: "width: 25px;" });

      return fields;
    },

    $isBusy: {
      get() {
        return this.isBusy;
      },

      set(val) {
        return this.$emit("update:isBusy", val);
      },
    },

    $currentPage: {
      get() {
        return this.currentPage;
      },

      set(val) {
        return this.$emit("update:currentPage", val);
      },
    },

    $sortBy: {
      get() {
        return this.sortBy;
      },

      set(val) {
        return this.$emit("update:sortBy", val);
      },
    },

    $sortDesc: {
      get() {
        return this.sortDesc;
      },

      set(val) {
        return this.$emit("update:sortDesc", val);
      },
    },
  },

  methods: {
    refresh() {
      this.$emit("before:refresh");

      this.$refs.baseTable.refresh();

      this.$emit("after:refresh");
    },

    async setDataItem() {
      this.itemData = await this.provider;
    },
  },

  mounted() {
    this.setDataItem = debounce(this.setDataItem, 500);
  },
};
</script>
